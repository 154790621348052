import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'List of Forms and Documents';
    const desc = `You'll need to draft these legal documents to operate your direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="legal">
        <Head title={title} description={desc} url="/todo/legal/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
